<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: headerBreadcrumbItem1.name }">
              {{ headerBreadcrumbItem1.text }}
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: headerBreadcrumbItem2.name,
                params: headerBreadcrumbItem2.params,
              }"
            >
              {{ headerBreadcrumbItem2.text }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active
              >{{
                isReadOnly
                  ? displayTextStaff("view_staff", "查看帳號")
                  : isEditing
                  ? displayTextStaff("edit_staff", "編輯帳號")
                  : displayTextStaff("add_staff", "新增帳號")
              }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                {{
                  isReadOnly
                    ? displayTextStaff("view_staff", "查看帳號")
                    : isEditing
                    ? displayTextStaff("edit_staff", "編輯帳號")
                    : displayTextStaff("add_staff", "新增帳號")
                }}
              </h4>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>

                <b-form-group
                  v-if="isEditing && hiddenField('branch_id')"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('branch_id') + displayTextStaff('staff_belong_branch', '所屬分店')"
                  label-for="branch_id"
                >
                  <div v-if="fromBranchPage">
                    <b-breadcrumb
                      class="mb-0 p-0"
                      :items="breadcrumb"
                    ></b-breadcrumb>
                  </div>
                  <div v-else class="row">
                    <div
                      v-for="branch in staff.branches"
                      class="col-8"
                      v-bind:key="branch.id"
                    >
                      <b-breadcrumb
                        class="mb-0 p-0"
                        :items="getBranchBreadcrumb(branch)"
                      ></b-breadcrumb>
                    </div>
                    <div class="col-4">
                      <b-button
                        v-if="
                          checkPermission([consts.STAFF_BRANCH_MODIFY]) &&
                          !isReadOnly
                        "
                        @click="goToEditStaffBranch"
                        variant="success"
                      >
                        {{
                          displayTextStaff(
                            "staff_set_branch_action",
                            "編輯所屬分店"
                          )
                        }}
                      </b-button>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="!v$.staff.branch_id.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="
                    !isEditing && !isReadOnly && hiddenField('branch_id')
                  "
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('branch_id') + displayTextStaff('staff_belong_branch', '所屬分店')"
                >
                  <branch-select
                    class="form-control"
                    v-model="staff.branch_id"
                    :disabled="fromBranchPage || isReadOnly"
                  ></branch-select>
                  <b-form-invalid-feedback :state="!v$.staff.branch_id.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="fromBranchPage"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayTextStaff('staff.branch_sort' ,'店內順序')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="number"
                    min=1
                    v-model="staff.branch_order"
                    :readonly="isReadOnly"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    '*' + displayTextStaff('staff_role', '員工角色')
                  "
                  v-if="hiddenField('role_code')"
                >
                  <b-form-select
                    v-model="staff.role_code"
                    :state="v$.staff.role_code.$error ? false : null"
                    :options="roleOptions"
                    :disabled="isReadOnly"
                    class="mr-3"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!v$.staff.role_code.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    '*' + displayTextStaff('staff_code', '員工編號')
                  "
                  v-if="hiddenField('employee_code')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.staff.employee_code.$error ? false : null"
                    v-model="staff.employee_code"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!v$.staff.employee_code.$error"
                  >
                    此欄位為必填，且只能是英文、數字、-、_、. 、+
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    displayRequireSign('name') + displayTextStaff('staff_name', '員工姓名')
                  "
                  label-for="input-1"
                  v-if="hiddenField('name')"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.staff.name.$error ? false : null"
                    v-model="staff.name"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.name.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('avatar') + displayTextStaff('staff_avatar', '個人頭像')"
                  label-for="input-1"
                  v-if="hiddenField('avatar')"
                >
                  <li
                    class="s-image-list-item s-draggable"
                    v-if="image || staff.avatar_url"
                  >
                    <img
                      class="s-cropped-image"
                      :src="image || staff.avatar_url"
                    />
                    <button
                      class="btn btn-danger s-delete-btn"
                      type="button"
                      @click="deleteImage()"
                    >
                      Ｘ
                    </button>
                  </li>
                  <li class="s-image-list-item" v-else>
                    <ImageSelector @input="selectImage">
                      <template #trigger="{ openFileSelector }">
                        <button
                          class="btn s-trigger-btn"
                          @click="openFileSelector"
                        >
                          ＋ 加入照片
                        </button>
                      </template>
                    </ImageSelector>
                  </li>
                  <b-form-invalid-feedback :state="!v$.staff.avatar.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('email') + displayTextStaff('email', 'Email')"
                  v-if="hiddenField('email')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.staff.email.$error ? false : null"
                    v-model="staff.email"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.email.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('mobile_phone') + displayTextStaff('mobile', '手機')"
                  v-if="hiddenField('mobile_phone')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.mobile_phone"
                    :state="v$.staff.mobile_phone.$error ? false : null"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.mobile_phone.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <FormField
                    v-model="staff.job_level"
                    :setting="formSetting('job_level')"
                    :formLabel="displayRequireSign('job_level') + displayTextStaff('staff_job_level', '職稱')"
                    :isError="v$.staff.job_level.$error"
                    :errorMessage="displayTextStaff('staff_is_required', '此欄位為必填')"
                    :isReadOnly="isReadOnly"
                    :isShow="hiddenField('job_level')"
                />
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                   displayRequireSign('service_unit') + displayTextStaff('staff_service_unit', '服務單位')
                  "
                  v-if="hiddenField('service_unit')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.service_unit"
                    :state="v$.staff.service_unit.$error ? false : null"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.service_unit.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <FormField
                    v-model="staff.dept_name"
                    :setting="formSetting('dept_name')"
                    :formLabel="displayRequireSign('dept_name') + displayTextStaff('staff_dept', '部門')"
                    :isError="v$.staff.dept_name.$error"
                    :errorMessage="displayTextStaff('staff_is_required', '此欄位為必填')"
                    :isReadOnly="isReadOnly"
                    :isShow="hiddenField('dept_name')"
                />
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('description') + displayTextStaff('staff_description', '描述')"
                  v-if="hiddenField('description')"
                >
                  <b-form-textarea
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.description"
                    :state="v$.staff.description.$error ? false : null"
                    :readonly="isReadOnly"
                    row="3"
                  ></b-form-textarea>
                  <b-form-invalid-feedback :state="!v$.staff.description.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('office_phone') + displayTextStaff('staff_phone', '市話')"
                  v-if="
                    hiddenField('office_phone_area') ||
                    hiddenField('office_phone') ||
                    hiddenField('office_phone_ext')
                  "
                >
                  <div class="d-flex w-100 city-selector-set-has-value">
                    <b-input-group prepend="(" append=")" class="col-3 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone_area"
                        :disabled="isReadOnly"
                        placeholder="區碼"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group append="#" class="col-8 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone"
                        :disabled="isReadOnly"
                        placeholder="市話"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-input
                      class="mb-2 mb-sm-0 col-1"
                      type="text"
                      v-model="staff.office_phone_ext"
                      :disabled="isReadOnly"
                      placeholder="分機"
                    ></b-form-input>
                  </div>
                  <b-form-invalid-feedback
                    :state="
                      !v$.staff.office_phone_area.$error &&
                      !v$.staff.office_phone.$error &&
                      !v$.staff.office_phone_ext.$error
                    "
                  >
                    此欄位為必須為數字
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('is_enabled') + displayTextStaff('is_enabled', '是否啟用')"
                  v-if="hiddenField('is_enabled')"
                >
                  <b-form-checkbox
                    class="mb-2 mr-sm-2 mb-sm-0"
                    v-model="staff.is_enabled"
                    :disabled="isReadOnly"
                    :state="v$.staff.is_enabled.$error ? false : null"
                  ></b-form-checkbox>
                  <b-form-invalid-feedback :state="!v$.staff.is_enabled.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('left_at') + displayTextStaff('staff_leave_date', '離職日')"
                  v-if="hiddenField('left_at')"
                >
                  <datepicker
                    v-model="staff.left_at"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="isReadOnly"
                    :clear-button="true"
                  ></datepicker>
                  <b-form-invalid-feedback :state="!v$.staff.left_at.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="displayRequireSign('line_id') + displayTextStaff('line_uid', 'LINE UID')"
                  v-if="hiddenField('line_id')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.line_id"
                    :readonly="isReadOnly"
                    :state="v$.staff.line_id.$error ? false : null"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.line_id.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    displayRequireSign('line_contact_url') + displayTextStaff('staff_line_url', 'Line 連結網址')
                  "
                  v-if="hiddenField('line_contact_url')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.line_contact_url"
                    :state="v$.staff.line_contact_url.$error ? false : null"
                    :readonly="isReadOnly"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.line_contact_url.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    v-bind:label="
                      displayTextStaff('staff_generator_password', '自動產生密碼密碼')
                    "
                    v-if="!isEditing && hiddenField('password')"
                >
                <b-form-checkbox v-model="generatorPassword"></b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    '*' + displayTextStaff('staff_password', '密碼')
                  "
                  v-if="!isEditing && hiddenField('password')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    :type="passwordType"
                    v-model.trim="staff.password"
                    :state="v$.staff.password.$error ? false : null"
                    placeholder="密碼不能小於 6 個字元"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.password.$error">
                    此欄位為必填
                  </b-form-invalid-feedback></b-form-group
                >
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-bind:label="
                    '*' + displayTextStaff('staff_password_confirm', '確認密碼')
                  "
                  v-if="!isEditing && hiddenField('password')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    :type="passwordType"
                    :state="
                      v$.staff.password_confirmation.$error ? false : null
                    "
                    v-model.trim="staff.password_confirmation"
                    placeholder="密碼不能小於 6 個字元"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!v$.staff.password_confirmation.$error"
                  >
                    需與密碼相同
                  </b-form-invalid-feedback></b-form-group
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 100px"
            >
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
                >返回
              </b-button>
              <b-button
                v-if="!isReadOnly"
                @click="handleSubmit"
                variant="success"
                >儲存</b-button
              >
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import staff from "../../../apis/staff";
import branchApi from "../../../apis/branch";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import BranchSelect from "@/components/Dashboard/BranchSelect";
import imageApi from "@/apis/image";
import ImageSelector from "@/components/ImageSelector";
import { displayText } from "@/utils/dashboardUtils";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import roleApi from "@/apis/role";
import FormField from "@/components/Page/Dashboard/FormField";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Datepicker, BranchSelect, ImageSelector, FormField },

  validations() {
    const employeeCodeRegex = (value) => {
      return /^[a-zA-Z0-9-_.+]*$/.test(value);
    };
    let validation = {
      staff: {
        name: this.isRequiredFormData('name') ? { required } : {},
        employee_code: { required, employeeCodeRegex } , // 一定要有
        office_phone_area: this.isRequiredFormData('office_phone_area') ? { required, numeric } : { numeric },
        office_phone: this.isRequiredFormData('office_phone') ? { required, numeric } : { numeric },
        office_phone_ext: this.isRequiredFormData('office_phone_ext') ? { required, numeric } : { numeric },
        mobile_phone: this.isRequiredFormData('mobile_phone') ? { required } : {},
        email: this.isRequiredFormData('email') ? { required } : {},
        service_unit: this.isRequiredFormData('service_unit') ? { required } : {},
        job_level: this.isRequiredFormData('job_level') ? { required } : {},
        dept_name: this.isRequiredFormData('dept_name') ? { required } : {},
        is_enabled: this.isRequiredFormData('is_enabled') ? { required } : {},
        left_at: this.isRequiredFormData('left_at') ? { required } : {},
        line_id: this.isRequiredFormData('line_id') ? { required } : {},
        line_contact_url: this.isRequiredFormData('line_contact_url') ? { required } : {},
        role_code: { required } , // 一定要有
        avatar: this.isRequiredFormData('avatar') ? { required } : {},
        branch_id: this.isRequiredFormData('branch_id') ? { required } : {},
        description: this.isRequiredFormData('description') ? { required } : {},
      },
    };
    if (!this.isEditing) {
      validation.staff.password = { required };
      validation.staff.password_confirmation = {
        sameAsPassword: (v) => v === this.staff.password,
      };
    }

    return validation;
  },

  data() {
    return {
      zh,
      consts: consts,
      showLoading: true,
      isEditing: true,
      validationErrors: null,
      generatorPassword:false,
      passwordType: "password",
      staff: {
        branches: [],
        name: null,
        mobile_phone: null,
        email: null,
        employee_code: null,
        branch_id: null,
        branch_order: null,
        service_unit: null,
        job_level: null,
        dept_name: null,
        is_enabled: true,
        left_at: null,
        line_id: null,
        line_contact_url: null,
        office_phone_area: null,
        office_phone: null,
        office_phone_ext: null,
        password: null,
        password_confirmation: null,
        role_code: null,
        description: null,
      },
      branches: [],
      activeBranch: null,
      image: null,
      roleOptions: [],
    };
  },
  watch: {
    "generatorPassword"(value) {
      if(value){
        const pd = this.generatePassword()
        this.passwordType = 'text'
        this.$set(this.staff, 'password', pd)
        this.$set(this.staff, 'password_confirmation', pd)
      } else {
        this.passwordType = 'password'
        this.$set(this.staff, 'password', null)
        this.$set(this.staff, 'password_confirmation', null)
      }
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    ...mapGetters("route", ["from", "routeQuery"]),
    ...mapGetters("general", ["role"]),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),

    avatarUrl() {
      return this.staff.avatar_url
        ? this.staff.avatar_url
        : "https://via.placeholder.com/500x500.png?text=Empty";
    },
    isReadOnly() {
      return (
        this.$route.name === "StaffView" ||
        this.$route.name === "BranchStaffView" ||
        this.$route.name === "PartnerStaffView"
      );
    },
    fromBranchPage() {
      return !!this.$route.params.branchID;
    },
    fromPartnerPage() {
      return !!this.$route.params.partnerId;
    },
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.activeBranch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }) ?? [];

      return [
        root,
        ...parents,
        {
          text: this.activeBranch?.name,
          active: true,
        },
      ];
    },
    headerBreadcrumbItem1() {
      if (this.fromBranchPage) {
        return { name: "Branches", text: "分店管理" };
      } else if (this.fromPartnerPage) {
        return {
          name: "PartnerList",
          text: displayText("partner.partner_management"),
        };
      } else {
        return {
          name: "StaffList",
          text: displayText("staff.staff_management", "帳號管理"),
        };
      }
    },
    headerBreadcrumbItem2() {
      if (this.fromBranchPage) {
        return {
          name: "BranchStaffs",
          params: { branchID: this.$route.params.branchID },
          text: "店內帳號",
        };
      } else if (this.fromPartnerPage) {
        return {
          name: "PartnerStaffList",
          params: { partnerId: this.$route.params.partnerId },
          text: displayText("partner.partner_staff_list"),
        };
      } else {
        return {
          name: "StaffList",
          params: {},
          text: displayText("staff.staff_list", "帳號列表"),
        };
      }
    },
  },
  async mounted() {
    if (!this.$route.params.staff_id) {
      this.clearStaff();
      this.isEditing = false;
    } else {
      await this.fetchStaff();
      this.isEditing = true;
    }
    if (this.$route.params.branchID) {
      this.staff.branch_id = this.$route.params.branchID;
      this.fetchBranch();
    }
    if (this.$route.params.partnerId) {
      this.staff.partner_id = this.$route.params.partnerId;
    }
    await this.getRoleOptions();
    this.showLoading = false;
  },
  methods: {
    formSetting(field) {
      if (this.getModuleConfig("staff", "staff.field_setting")) {
        const setting = this.getModuleConfig("staff", "staff.field_setting").find((setting) => setting.key === field)
        if (setting) return setting;
      }
      return {}
    },
    generatePassword(){
      const charset1 = "abcdefghijklmnopqrstuvxyz"
      const charset2 = "ABCDEFGHIJKLMNOPQRSTUVXYZ"
      const charset3 = "0123456789"
      let password = ""
      for (let i = 0; i < 2; i++) {
        let randomIndex = Math.floor(Math.random() * charset1.length)
        password += charset1[randomIndex]
      }
      for (let i = 0; i < 2; i++) {
        let randomIndex = Math.floor(Math.random() * charset2.length)
        password += charset2[randomIndex]
      }
      for (let i = 0; i < 4; i++) {
        let randomIndex = Math.floor(Math.random() * charset3.length)
        password += charset3[randomIndex]
      }

      return password.split("").sort(()=>{return Math.random()>0.5?1:-1}).join("");
    },
    isRequiredFormData(field) {
      let isRequired = false;
      if (this.getModuleConfig("staff", "staff.required_fields")) {
        isRequired = this.getModuleConfig("staff", "staff.required_fields").includes(field);
      }

      if (this.getModuleConfig("staff", "staff.staff_roles")) {
        const roleConfig = this.getModuleConfig("staff", "staff.staff_roles");
        let roleSetting = roleConfig.find((role) => role.role === this.staff.role_code);
        if (roleSetting) isRequired = roleSetting.required_fields?.includes(field) || isRequired;
      }

      if (!this.hiddenField(field) && isRequired) {
        this.$swal({
          title: "錯誤",
          text: `${field} 欄位為必填，不能被隱藏`,
          type: "error",
        });
      }

      return isRequired;
    },
    displayRequireSign(field) {
      if (this.isRequiredFormData(field)) {
        return '* '
      } else {
        return '';
      }
    },
    hiddenField(field) {
      let isShow = true;
      if (this.getModuleConfig("staff", "staff.hidden_fields")) {
        isShow = !this.getModuleConfig(
          "staff",
          "staff.hidden_fields"
        )?.includes(field);
      }
      if (this.getModuleConfig("staff", "staff.staff_roles")) {
        const roleConfig = this.getModuleConfig("staff", "staff.staff_roles");
        let roleSetting = roleConfig.find((role) => role.role === this.staff.role_code);
        if (roleSetting) isShow = !roleSetting.hidden_fields.includes(field) && isShow;
      }
      return isShow;
    },
    async getRoleOptions() {
      const { data } = await roleApi.getSubordinate(this.organization.id);
      this.roleOptions = data.data
        .sort((a, b) => b.level - a.level)
        .map((role) => {
          return { value: role.code, text: role.name };
        });
      this.staff.role_code = this.staff.role_code ?? this.roleOptions[0].value;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    goToEditStaffBranch() {
      this.$router.push({
        name: "StaffSetBranch",
        params: {
          staff_id: this.staff.id,
        },
      });
    },
    getBranchBreadcrumb(branch) {
      const root = {
        text: "最上層",
        active: true
      };

      const parents =
        branch.parents.map((parent) => {
          return {
            text: parent.parent_name,
            active: true
          };
        }) ?? [];

      return [
        root,
        ...parents,
        {
          text: branch.name,
          active: true
        },
      ];
    },
    displayTextStaff(key, defaultText) {
      if (this.fromPartnerPage) {
        return displayText(`partner.${key}`, defaultText);
      } else {
        return displayText(`staff.${key}`, defaultText);
      }
    },
    async fetchStaff() {
      const response = await staff.getOneStaff(this.$route.params.staff_id);
      this.staff = response.data.data;
      this.staff.branch_id = this.fromBranchPage ? this.$route.params.branchID : this.staff.current_branch_id;
      response.data.data.branches.forEach((branch) => {
        if (branch.org_id === this.organization.id) {
          this.branches.push(branch);
        }
        if (branch.id === this.staff.branch_id) {
          this.staff.branch_order = branch.pivot.order;
        }
      });
      this.showLoading = false;
    },
    async fetchBranch() {
      const { data } = await branchApi.getBranch(this.$route.params.branchID);
      this.activeBranch = data.data;
    },
    clearStaff() {
      this.staff = {
        name: null,
        mobile_phone: null,
        email: null,
        employee_code: null,
        branch_id: null,
        service_unit: null,
        job_level: null,
        dept_name: null,
        is_enabled: true,
        left_at: null,
        line_id: null,
        line_contact_url: null,
        role_code: this.roleOptions[0]?.value ?? null,
        description: null,
      };
    },
    selectImage(image) {
      this.image = image;
    },
    deleteImage() {
      this.image = null;
      this.staff.avatar_url = null;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      if (this.image) {
        const resp = await this.uploadImage();
        if (!(resp.status && resp.status === 200)) {
          return;
        }
      }

      try {
        if (this.isEditing) {
          let response = await staff.updateStaff(this.staff);
          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "更新成功",
              type: "success",
            });
            this.staff = response.data.data;
            this.staff.branch_id = response.data.data.current_branch_id;

            this.navigateToRelevantPage();
          }
        } else {
          let response = await staff.storeStaff(this.staff);

          if (response.status && response.status === 200) {
            this.$swal.fire({
              title: "新增成功",
              type: "success",
            });

            this.navigateToRelevantPage();
          }
        }
      } catch (error) {
        if (error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: this.isEditing ? "更新失敗" : "新增失敗",
            type: "error",
          });
        }
      }
    },
    async uploadImage() {
      try {
        let response = await imageApi.uploadByBase64(this.image);
        this.staff.avatar_url = response.data.data.image.url;
        return response;
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    handleCancel() {
      this.navigateToRelevantPage();
    },
    navigateToRelevantPage() {
      if (this.fromBranchPage) {
        this.$router.push({
          name: "BranchStaffs",
          params: { branchID: this.$route.params.branchID },
        });
      } else if (this.fromPartnerPage) {
        if (this.from) {
          this.$router.push(this.from.fullPath);
        } else {
          this.$router.push({
            name: "PartnerStaffList",
            params: { partnerId: this.$route.params.partnerId },
          });
        }
      } else {
        this.$router.push({ name: "StaffList", query: this.routeQuery });
      }
    },
  },
};
</script>

<style scoped>
.s-image-list {
  margin: -0.75rem;
  display: flex;
  flex-wrap: wrap;
}

.s-image-list-item {
  position: relative;
  width: 7rem;
  height: 7rem;
  border: 1px solid #ddd;
  margin: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-cropped-image {
  width: 100%;
  height: 100%;
}

.s-delete-btn {
  position: absolute;
  top: -0.75rem;
  right: -0.75rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.s-image-selector {
  width: 100%;
  height: 100%;
}

.s-trigger-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
